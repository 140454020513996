
.goods-shoot-content {
  width: 100%;
  height: 100%;
  //   background-color: pink;
  padding: 20px;
  box-sizing: border-box;

  .header {
    display: flex;
    justify-content: space-between;
  }
}

.title {
  margin-bottom: 15px;
  font-size: 16px;
  display: flex;
  align-items: center;

  .left-icon {
    display: inline-block;
    width: 4px;
    height: 16px;
    background-color: #2dc079;
    margin-right: 6px;
    border-radius: 2px;
  }
}

.btn-plain {
  color: #1cb064;
  background: #e8f7f0;
  border-color: #a4dfc1;
}

.button {
  color: #fff;
  background-color: #1cb064;
  border-color: #1cb064;
}

.echarts-content {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
  padding-top: 20px;
}

.echarts {
  width: 100%;
  display: flex;
  flex: 1;

  .sale {
    flex: 1;
  }

  .order {
    flex: 1;
    margin-left: 20px;
  }

  .saleEcharts,
  .orderEcharts {
    height: 360px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}

.number {
  flex: 1;
  margin-top: 20px;

  .numberEcharts {
    height: 350px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}

.upload {
  display: flex !important;
  align-items: center;
}

.upload-btn {
  margin-right: 20px;
}

.fileInfo {
  font-size: 14px;
}

.filename {
  color: #1cb064;
}

.el-icon-close {
  color: red;
  margin-left: 5px;
}

.el-card {
  width: 100%;
  height: 100%;
}

::v-deep .el-card__body {
  width: 100%;
  height: 100%;
}

.noData {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 160px;
    height: 160px;
    display: block;
  }

  p {
    display: block;
    color: #666666;
  }
}

::v-deep .el-dialog__body {
  padding-top: 10px !important;
}
